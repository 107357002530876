import styled, { css } from 'styled-components';
import { Loader as LoaderComponent } from 'src/components';

export const LoaderWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Loader = styled(LoaderComponent)(
  ({ theme }) => css`
    max-height: ${theme.sz.s40};
    margin: auto;
    display: block;
  `,
);

export const Root = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.co.neutral99};
  color: ${({ theme }) => theme.co.neutral20};
  display: flex;
  flex-direction: column;
  font-size: ${({ theme }) => theme.DEPRECATED_ty.smaller};
  height: 100%;
  min-height: ${props => `calc(100vh - ${props.theme.sz.s16})`};
  padding: ${({ theme }) => theme.sz.s8} 0;
  width: 100%;
`;

export const Form = styled.form(
  ({ theme }) => css`
    display: grid;
    grid-row-gap: ${({ theme }) => theme.sz.s6};
    width: calc(100% - ${theme.sz.s4});
    margin: 0 ${theme.sz.s2};
    max-width: 400px;
    @media (max-width: ${theme.sz.lg}) {
      max-width: 288px;
    }
  `,
);

export const SocialButtonsWrapper = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-row-gap: ${({ theme }) => theme.sz.s4};
    margin: 0 ${theme.sz.s2};

    width: 400px;
    @media (max-width: ${theme.sz.lg}) {
      width: 288px;
    }
  `,
);

export const Title = styled.h1(
  ({ theme }) => css`
    color: ${theme.co.secondary20};
    font-family: ${theme.DEPRECATED_ty.boing};
    font-size: ${theme.DEPRECATED_ty.displaySmall};
    font-weight: ${theme.DEPRECATED_ty.bold};
    margin: 0 ${theme.sz.s2};
    padding-bottom: ${theme.sz.s8};
    text-align: center;
    max-width: 28rem;
  `,
);

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Links = styled.a<{
  $alignSelf?: string;
  $hasError?: boolean;
}>(
  ({ theme, $alignSelf, $hasError }) => css`
    align-self: ${$alignSelf || 'center'};
    color: initial;
    cursor: pointer;
    /* istanbul ignore next: tested manually */
    font-size: ${theme.DEPRECATED_ty.smaller};
    padding-top: ${$hasError ? theme.sz.s12 : theme.sz.s5};
    text-align: center;
    text-decoration: underline;
  `,
);

export const SignupLink = styled(Links)(
  ({ theme }) => css`
    font-size: ${theme.DEPRECATED_ty.mediumLarge};
  `,
);
