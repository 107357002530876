import React from 'react';
import * as S from './styles';

/**
 * A simple Component that styles the default html <label>
 *
 * This element can attach to an input either implicitly or
 * explicitly (like a regular input). Prefer the explicit
 * method (example 1), but  implicit is fine if you don't
 * have the required id
 *
 * @example
 *
 *   <Label htmlFor='input-it'>
 *   <MyInput id='input-it' />
 *
 * @example
 *
 *   <Label>
 *     Card detail
 *     <SomeInput />
 *   </Label>
 */

interface LabelProps {
  children?: React.ReactNode;
  disabled?: boolean;
  htmlFor?: string;
}

const Label = ({ disabled, ...props }: LabelProps) => <S.Label $disabled={disabled} {...props} />;

export default Label;
