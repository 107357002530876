import React, { HTMLProps, forwardRef } from 'react';
import * as S from './styles';

/* Allow any to avoid difficult styled-components typings */
/* eslint @typescript-eslint/no-explicit-any: 0 */

interface Props extends HTMLProps<HTMLInputElement> {
  error?: TranslatedNode;
  large?: boolean;
}

const Input = forwardRef<HTMLInputElement, Props>(
  ({ error, large, disabled, as, ...rest }, ref) => (
    <S.Variables
      styleProps={{
        hasError: !!error,
        disabled: disabled as boolean,
        large: large as boolean,
      }}
    >
      <S.Container>
        <S.Input ref={ref as any} as={as as any} disabled={disabled} {...rest} />
        <S.ErrorContainer hidden={!error}>
          <S.Error key="input-error">{error || ''}</S.Error>
        </S.ErrorContainer>
      </S.Container>
    </S.Variables>
  ),
);

Input.displayName = 'Input';

export default Input;
