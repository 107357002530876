export const getTimezone = () =>
  Intl && Intl.DateTimeFormat
    ? Intl.DateTimeFormat().resolvedOptions().timeZone
    : /* istanbul ignore next: untested branch of code, please test */ 'unknown';

/**
 * This makes it easier to mock components that require
 * the date (UTC)
 * This isn't tested - mocking dates is too tricky!
 */
/* istanbul ignore next */
export const getCurrentDate = () => new Date(Date.now());

/** Converts to unix timestamp required by backend
 * Defaults to now if no datestring is provided
 * Returns value in seconds */
export const convertDateStringToUnixTimestamp = (dateString: string): number => {
  return Math.round(Date.parse(dateString) / 1000);
};

export const getNowAsUnixTimestamp = () => {
  return Math.round(getCurrentDate().valueOf() / 1000);
};
