import styled, { DefaultTheme } from 'styled-components';

export interface GetColorTypes {
  disabled?: boolean;
  theme: DefaultTheme;
}

export const getColor = ({ theme, disabled }: GetColorTypes) =>
  disabled ? theme.co.secondary90 : theme.co.neutral30;

export const Label = styled.label<{ $disabled?: boolean }>`
  color: ${({ theme, $disabled }) => getColor({ theme, disabled: $disabled })};
  display: inline-block;
  font-weight: ${({ theme }) => theme.DEPRECATED_ty.bold};
  width: 100%;
`;
