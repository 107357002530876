import React, { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import theme from 'src/utils/theme';
import * as S from './styles';

const msgs = defineMessages({
  home: {
    id: 'header_link_home',
  },
  learn: {
    id: 'scenario_dashboard_sidebar_learn_tab',
  },
});
interface Props {
  url: string;
  isMobileVisible: boolean;
  fitContent?: boolean;
  iconColor?: 'primary20';
  largerIcon?: boolean;
}

export const LogoContainer: FC<Props> = ({
  url,
  isMobileVisible,
  fitContent,
  iconColor,
  largerIcon,
}) => {
  const intl = useIntl();
  const logoText = intl.formatMessage(msgs.home);

  return (
    <S.MemriseIconContainer
      href={url}
      data-testid="memrise-icon-container"
      $isMobileVisible={isMobileVisible}
      $fitContent={fitContent}
      title={logoText}
      aria-label={logoText}
    >
      <S.LogoIcon
        name="memrise"
        $iconColor={iconColor ? theme.co[iconColor] : undefined}
        $larger={largerIcon}
      />
    </S.MemriseIconContainer>
  );
};
