import React, { FC, ReactNode } from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { LogoContainer } from 'src/components';
import HeaderDropdownIcon from '../HeaderDropdownIcon';
import { DropdownItemProps } from '../DropdownMenu/DropdownItem';
import * as S from './styles';

const msgs = defineMessages({
  courses: {
    defaultMessage: 'Courses',
    id: 'nav_link_courses',
  },
  home: {
    defaultMessage: 'Home',
    id: 'header_link_home',
  },
  groups: {
    defaultMessage: 'Groups',
    id: 'header_link_groups',
  },
  // These keys will need adding in WEBSITE-3337
  forum: {
    id: 'header_link_forum',
    defaultMessage: 'Forum',
  },
  help: {
    id: 'header_link_help',
    defaultMessage: 'Help',
  },
  languages: {
    id: 'header_link_languages',
    defaultMessage: 'Languages',
  },
  seeMore: {
    id: 'scenario_learnable_list_button_see_more',
  },
  beta: {
    id: 'communicate_membot_poweredby_GPT3',
  },
});

interface Props {
  linkConfig?: LinkConfig;
  logoUrl?: string;
  currentUrl?: string;
  lightHeader?: boolean;
  minimalHeader?: boolean;
  dropdown?: ReactNode;
  children?: ReactNode;
  isMobile?: boolean;
  hasBetaTag?: boolean;
}

type LinkConfig = 'none' | 'base' | 'dashboard' | 'coreLoop';

interface LinkInfo extends Props {
  url: string;
  label: MessageDescriptor;
  newTab?: boolean;
  onClick?: () => void;
}

const Header: FC<Props> = ({
  linkConfig = 'base',
  logoUrl = '/',
  lightHeader,
  minimalHeader,
  currentUrl,
  dropdown,
  children,
  hasBetaTag,
}) => {
  const intl = useIntl();

  const linkConfigMaps: Record<LinkConfig, LinkInfo[]> = {
    none: [],
    base: [
      {
        url: '/community/courses/',
        label: msgs.courses,
      },
    ],
    dashboard: [
      {
        url: '/dashboard',
        label: msgs.home,
      },
      {
        url: '/community/courses/',
        label: msgs.courses,
      },
      {
        url: '/groups/',
        label: msgs.groups,
      },
    ],
    coreLoop: [
      {
        url: 'https://memrisebeta.zendesk.com',
        label: msgs.help,
        newTab: true,
      },
    ],
  } as Record<LinkConfig, LinkInfo[]>;

  const links = linkConfigMaps[linkConfig];
  const hasLinks = !!links.length;

  return (
    <S.HeaderWrapper
      data-testid="header"
      $background={lightHeader ? 'neutral99' : 'secondary20'}
      $padding="s4"
    >
      {hasLinks && (
        <S.HamburgerMenu data-testid="hamburgerMenu">
          <HeaderDropdownIcon
            ariaText={intl.formatMessage(msgs.seeMore)}
            iconName={minimalHeader ? 'burgerThin' : 'burger'}
            position="center"
            useCurrentColor
            dropdownSections={[
              {
                dropdownItems: [
                  ...links.map(
                    ({ url, label, newTab, onClick }) =>
                      ({
                        type: 'link',
                        linkText: intl.formatMessage(label),
                        href: url,
                        newTab,
                        onClick,
                      }) as DropdownItemProps,
                  ),
                ],
              },
            ]}
          />
        </S.HamburgerMenu>
      )}
      {minimalHeader && <S.ChildrenWrapper $minimalHeader>{children}</S.ChildrenWrapper>}
      <S.HeaderWrapperInner $minimalHeader={minimalHeader}>
        <S.HeaderInner>
          {!minimalHeader && <LogoContainer url={logoUrl} isMobileVisible={!hasLinks} />}
          {hasBetaTag && (
            <S.BetaTag
              data-testid="Beta-tag"
              href="https://memrise.zendesk.com/hc/en-us/articles/9314957339281"
              target="_blank"
              rel="noopener noreferrer"
            >
              {intl.formatMessage(msgs.beta)}
            </S.BetaTag>
          )}
          {hasLinks && (
            <S.IndividualLinks>
              <S.HeaderLinks data-testid="links">
                {links?.map(({ url, label, newTab }) => (
                  <S.Link
                    key={url}
                    href={url}
                    $isActive={currentUrl === url}
                    $lightHeader={lightHeader}
                    {...(newTab
                      ? {
                          target: '_blank',
                          rel: 'noreferrer noopener',
                        }
                      : {})}
                  >
                    {intl.formatMessage(label)}
                  </S.Link>
                ))}
              </S.HeaderLinks>
            </S.IndividualLinks>
          )}
        </S.HeaderInner>
        {!minimalHeader && <S.ChildrenWrapper>{children}</S.ChildrenWrapper>}
        <S.HeaderInner>{dropdown}</S.HeaderInner>
      </S.HeaderWrapperInner>
    </S.HeaderWrapper>
  );
};

export default Header;
