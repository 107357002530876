import { AuthApi } from 'src/api';
import { getTimezone } from 'src/utils/dateTime';

export interface GoogleResponse {
  clientId?: string;
  credential: string;
  select_by: string;
}

export const authenticateWithMobileToken = async (
  accessToken: string,
  /** Data parameter used by Marketing to track campaigns */
  acquisition_channel?: string,
) => {
  await AuthApi.web({
    token: accessToken,
    acquisition_channel,
  });
};

export const authenticateWithGoogle = async (
  authResponse: GoogleResponse,
  /** Data parameter used by Marketing to track campaigns */
  acquisition_channel?: string,
) => {
  const { credential: id_token } = authResponse;
  const timezone = getTimezone();
  const { access_token, user } = await AuthApi.google({
    id_token,
    timezone,
  });

  await AuthApi.web({
    token: access_token.access_token,
    invalidate_token_after: true,
    acquisition_channel,
  });

  return { user };
};

export interface AuthResponseType {
  accessToken: string;
}

export const authenticateWithFacebook = async (
  authResponse: AuthResponseType,
  /** Data parameter used by Marketing to track campaigns */
  acquisition_channel?: string,
) => {
  const fb_access_token = authResponse.accessToken;
  const timezone = getTimezone();
  const { access_token, user } = await AuthApi.facebook({
    fb_access_token,
    timezone,
  });

  // Login to web and set the session id cookie
  await AuthApi.web({
    token: access_token.access_token,
    invalidate_token_after: true,
    acquisition_channel,
  });

  return { user };
};

export interface AuthorizationTypeApple {
  id_token: string;
}

export const authenticateWithApple = async (
  authResponse: AuthorizationTypeApple,
  /** Data parameter used by Marketing to track campaigns */
  acquisition_channel?: string,
) => {
  const id_token = authResponse.id_token;
  const timezone = getTimezone();
  const { access_token, user } = await AuthApi.apple({
    id_token,
    timezone,
  });

  await AuthApi.web({
    token: access_token.access_token,
    invalidate_token_after: true,
    acquisition_channel,
  });

  return { user };
};

export const signUpWithEmail = async (
  signUpDetails: {
    email: string;
    password: string;
    username?: string;
  },
  /** Data parameter used by Marketing to track campaigns */
  acquisition_channel?: string,
) => {
  const timezone = getTimezone();
  const { access_token, user } = await AuthApi.signUp({
    ...signUpDetails,
    timezone,
  });

  // Login to web and set the session id cookie
  await AuthApi.web({
    token: access_token.access_token,
    invalidate_token_after: true,
    acquisition_channel,
  });

  return { user };
};

export const signInWithEmail = async (signInDetails: { username: string; password: string }) => {
  const { access_token, user } = await AuthApi.accessToken(signInDetails);

  // Login to web and set the session id cookie
  await AuthApi.web({
    token: access_token.access_token,
    invalidate_token_after: true,
  });

  return { user };
};
