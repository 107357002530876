import defaultStyled, { css as defaultCss, DefaultTheme } from 'styled-components';
import createVariablesWrapper from '../createVariablesWrapper';

type StyleProps = {
  hasError: boolean;
  disabled: boolean;
  large: boolean;
};

type StyleVariables = {
  inputBackground: string;
  animationTiming: string;
  borderColor: string;
  placeholderColor: string;
  inputBorderRadius: string;
  errorOpacity: '0' | '1';
  errorColour: string;
  paddingVertical: string;
  paddingHorizontal: string;
};

const styled = defaultStyled;
const css = defaultCss;

export interface GetBorderColorTypes {
  disabled: boolean;
  hasError: boolean;
  theme: DefaultTheme;
}

export const getBorderColor = ({ disabled, hasError, theme }: GetBorderColorTypes) => {
  if (hasError) return theme.co.error60;
  if (disabled && !hasError) return theme.co.secondary90;

  return theme.co.neutral20;
};

export const Variables = createVariablesWrapper<StyleProps, StyleVariables>(
  ({ hasError, disabled, large }) =>
    theme => ({
      inputBackground: theme.co.neutral99,
      animationTiming: '300ms cubic-bezier(0, 1, 0.75, 1)',
      borderColor: getBorderColor({ disabled, hasError, theme }),
      placeholderColor:
        /* istanbul ignore next: untested branch of code, please test */
        hasError && disabled
          ? theme.co.error80
          : hasError
            ? theme.co.error60
            : disabled
              ? theme.co.neutral90
              : theme.co.secondary60,
      /* istanbul ignore next: untested branch of code, please test */
      inputBorderRadius: large ? theme.bo.medium : theme.bo.small,
      errorOpacity: hasError ? '0' : '1',
      errorColour: theme.co.error60,
      /* istanbul ignore next: untested branch of code, please test */
      paddingVertical: large ? theme.sz.s4 : theme.sz.s2,
      /* istanbul ignore next: untested branch of code, please test */
      paddingHorizontal: large ? theme.sz.s4 : theme.sz.s2,
      ...theme,
    }),
);

export const Container = styled.div`
  position: relative;
`;

export const Input = styled.input(
  ({ theme }) => css`
    background-color: ${theme.co.neutral99};
    border-radius: ${theme.inputBorderRadius};
    border: 2px solid ${theme.borderColor};
    font-size: inherit;
    margin-top: ${theme.sz.s2};
    position: relative;
    padding: ${theme.paddingVertical} ${theme.paddingVertical};
    width: 100%;
    z-index: ${theme.zi.z5};

    &::placeholder {
      color: ${theme.placeholderColor};
    }
  `,
);

interface ErrorContainerProps {
  readonly hidden: boolean;
}

export const ErrorContainer = styled.div<ErrorContainerProps>`
  pointer-events: none;
  position: relative;
  display: block;
  width: 100%;
  z-index: ${props => props.theme.zi.z4};
  opacity: ${props => props.theme.errorOpacity};
  transition:
    transform ${props => props.theme.animationTiming},
    opacity ${props => props.theme.animationTiming};
  opacity: ${props => (props.hidden ? '0' : '1')};
  transform: translateY(${props => (props.hidden ? '0' : '-100%')});
`;

export const Error = styled.div(({ theme }) => {
  const padding = `calc( ${theme.inputBorderRadius})
      ${theme.sz.s2} ${theme.sz.s1}
      ${theme.paddingHorizontal}`;
  return css`
    align-items: center;
    background-color: ${theme.errorColour};
    border-bottom-left-radius: ${theme.inputBorderRadius};
    border-bottom-right-radius: ${theme.inputBorderRadius};
    color: ${theme.co.neutral99};
    display: flex;
    font-size: ${theme.DEPRECATED_ty.smaller};
    font-weight: ${theme.DEPRECATED_ty.bold};
    margin-top: -${theme.inputBorderRadius};
    padding: ${padding};
    position: absolute;
    width: 100%;
  `;
});
