import styled, { css } from 'styled-components';
import Icon from '../Icon';

export const MemriseIconContainer = styled.a<{
  $isMobileVisible?: boolean;
  $fitContent?: boolean;
}>(
  ({ theme, $isMobileVisible, $fitContent }) => css`
    cursor: pointer;
    margin-right: ${$fitContent ? 0 : theme.sz.s12};
    width: ${$fitContent ? 'fit-content' : '100%'};

    ${!$isMobileVisible &&
    css`
      @media (max-width: ${theme.sz.maxMobileBreakpoint}) {
        display: none;
      }
    `};
  `,
);

export const LogoIcon = styled(Icon)<{
  $iconColor?: string;
  $larger?: boolean;
}>(
  ({ theme, $iconColor, $larger }) => css`
    display: inline-block;
    height: ${$larger ? theme.sz.s16 : theme.sz.s10};
    width: ${$larger ? theme.sz.s16 : theme.sz.s10};

    svg {
      height: 100%;
      width: 100%;

      ${$iconColor &&
      css`
        * {
          fill: ${$iconColor};
        }
      `}
    }
  `,
);
